<template>
  <dx-util-popup
    ref="fbmShipmentItemsPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="fbmShipmentItemsPopupShown"
  >
    <div>
      <div class="master-detail-caption">
        Shipment Items
      </div>
      <dx-data-grid
        :data-source="dataSource"
        :show-borders="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :column-auto-width="true"
      >
        <dx-load-panel :enable="true" />
        <!-- <dx-column data-field="companyName" /> -->
        <!-- <dx-column data-field="storeName" /> -->
        <dx-column data-field="title" />
        <dx-column data-field="upc" />
        <dx-column data-field="asin" />
        <dx-column data-field="msku" />
        <dx-column data-field="quantity" />
        <dx-column data-field="receiveTime" data-type="date" />
        <dx-column data-field="shipTime" data-type="date" />
        <dx-column data-field="status" />
        <dx-column data-field="notes" />
        <dx-column type="buttons" caption="Actions">
          <dx-button
            ref="btnAddCharge"
            hint="Charge Details"
            icon="money"
            css-class="text-success"
            :on-click="onClickAddCharges"
          />
        </dx-column>
      </dx-data-grid>
      <div class="col-md-12">
        <!--Begin:: Charge Component-->
        <dx-util-popup
          id="popupCharges"
          ref="popupChargesRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :height="720"
          content-template="popup-content"
          :title="chargePopupTitle"
          @shown="onShownChargesPopup"
          @hiding="onHidingChargesPopup"
        >
          <dx-util-position at="center" my="center" />
          <template #popup-content>
            <charges v-if="isCreated" :item-id="itemId" :read-only="!hasPermission" :products="selectedProduct" :load-with-item-id="true" item-type="product" @on-emit-get-charges="onEmitGetCharges" />
          </template>
          <dx-util-toolbar-item v-if="hasPermission" :visible="chargeUpdateAllowed" widget="dxButton" toolbar="bottom" location="after" :options="saveChargesOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
        </dx-util-popup>
        <!--End:: Charge Component-->
      </div>
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { emitter } from '@/libs/bus'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import Charges from '@components/charge/Charges.vue'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'

export default {
  components: {
    Charges,
  },
  mixins: [GridBase],
  props: {
    componentId: {
      type: String,
      default: '',
    },
    shipmentDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      dataSource: [],
      chargeUpdateAllowed: false,
      itemId: null,
      isCreated: false,
      selectedProduct: [],
      chargePopupTitle: '',
    }
  },
  computed: {
    fbmShipmentItemsPopup() {
      return this.$refs.fbmShipmentItemsPopupRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
        onClick: () => {
          emitter.emit('on-emit-set-charges')
        },
      }
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      async handler() {
        this.fbmShipmentItemsPopup.show()
        await this.getShipmentItems()
      },
    },
  },
  methods: {
    async getShipmentItems() {
      this.dataSource = await outboundShippingService.listMerchantShipmentItems(this.shipmentDetails.id)
    },
    fbmShipmentItemsPopupShown() {
      this.popupTitle = `Shipment Items for ${this.shipmentDetails.boxName} `
    },
    isActiveChargeAction(e) {
      return e.row.data.invoiceId && e.row.data.invoiceId !== null
    },
    onClickAddCharges(e) {
      this.selectedProduct.length = 0
      this.chargeUpdateAllowed = !this.isActiveChargeAction(e)
      const { data } = e.row
      this.itemId = data.id
      this.selectedProduct.push({
        id: data.id,
        text: `${data.asin}-${data.title}`,
      })
      const popup = this.$refs.popupChargesRef.instance
      popup.show()
      this.$nextTick(() => {
        this.isCreated = true
      })
    },
    onShownChargesPopup() {
      this.chargePopupTitle = 'Shipment Charges'
    },
    onHidingChargesPopup() {
      this.$nextTick(() => {
        this.itemId = null
        this.isCreated = false
      })
    },
    onEmitGetCharges(payload) {
      const charges = payload
      itemChargeService.create(this.itemId, charges).then(() => {
        const popup = this.$refs.popupChargesRef.instance
        popup.hide()
      })
    },
  },
}
</script>

<style lang="scss" >
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 20px 20px 15px 15px !important;
}
.master-detail-caption {
  padding: 0 0 10px 10px;
  font-size: 20px;
  font-weight: bold;
}
</style>
